<div class="sidebar-menu" id="menu-wrapper">
  <!-- #region HEADER -->
  <!-- ----------------------------------------------------------------------------------------------- -->
  <horizon-sidebar-menu
    class="sidebar-menu__header"
    [sideBarMenuConfig]="menuSettingsService.sidebarMenuConfig"
    [menuRoutingPaths]="menuRoutingPaths"
    [menuItemsPermissions]="menuItemsPermissions"
    [mainFontColor]="globalSettings.colors.mainColor"
    [applyPermissions]="true"
    *ngIf="showHeader"
  >
    <div sidebar>
      <horizon-app-header
        [showLogoImg]="headerSettingsService.headerSettings.showLogoImg"
        [showSearch]="headerSettingsService.headerSettings.showSearch"
        [canUserSearch]="canUserSearch"
        [showToolbarActions]="headerSettingsService.headerSettings.showToolbar"
        [showUserAvatar]="headerSettingsService.headerSettings.showUserAvatar"
        [showUserOptionsMenu]="
          headerSettingsService.headerSettings.showUserOptionsMenu
        "
        [logoImg]="headerSettingsService.headerSettings.logoImg"
        [toolbarOptions]="headerSettingsService.toolbarOptions"
        [userOptionsMenuItems]="headerSettingsService.customMenuOptions"
        [user]="user"
        [userPhoto]="userPhoto"
        [currentLanguage]="currentLanguage"
        [hasRecentsSearch]="true"
        [dataRecentsSearch]="dataRecentsSearch"
        [recentSearchLayout]="recentSearchContent"
        [templateRecentSearch]="templateRecentSearch"
        [mainFontColor]="globalSettings.colors.mainColor"
        [secondaryFontColor]="globalSettings.colors.tertiaryColor"
        [showSystemDate]="true"
        [systemDate]="systemDate"
        [textSearchInput]="true"
        (logoImgClicked)="goHome()"
        (searchClicked)="onSearch($event)"
        (languageChanged)="languageChanged($event)"
        (totalData)="totalData()"
        (updateData)="updateData($event)"
        (logoutClicked)="logout()"
        (selectItemClicked)="selectItemClick($event)"
        (toolbarItemClicked)="onToolbarItemClicked($event)"
      >
        <!--componente recent-search-->
      </horizon-app-header>
      <horizon-genesys-telephony
        *ngIf="startGenesysTelephony"
        [showGenesysTelephony]="showGenesysTelephony"
        [showIncomingCallNotification]="showIncomingCallNotification"
        (genesysLogout)="genesysLogout()"
      ></horizon-genesys-telephony>
    </div>
  </horizon-sidebar-menu>

  <!--endregion -->

  <!-- #region CONTENT -->
  <!-- ----------------------------------------------------------------------------------------------- -->
  <div class="sidebar-menu__main" id="main-text">
    <div class="sidebar-menu__main--content">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
  <!--endregion -->
</div>

<!-- #region TOAST -->
<horizon-toast-notification
  data-qa="horizon-toast-notification"
  [toasts]="toastNotifications"
></horizon-toast-notification>
<!-- #endregion -->

<!-- #region NOTIFICATIONS -->
<horizon-dialog-notification-modal
  data-qa="horizon-dialog-notification-modal"
  [dialogNotificationObj]="notificationsDialog"
  [dialogWidth]="'500'"
  (confirmModal)="confirmModal($event)"
  (cancelModal)="cancelModal()"
></horizon-dialog-notification-modal>
<!-- #endregion -->

<!-- #region LOADING -->
<horizon-dialog-loading-modal
  *ngIf="showLoadingSpinner"
  [dialogTitle]="'horizon.loading'"
></horizon-dialog-loading-modal>
<!-- #endregion -->

<!-- #region open Genesys Substitute modal -->
<horizon-genesys-temporal-substitute
  *ngIf="showGenesysSubstituteModal"
  (genesysSubstituteSearch)="
    closeGenesysSubstituteModal($event.search, $event.event)
  "
  (closeSubstitute)="closeGenesysSubstituteModal(false)"
></horizon-genesys-temporal-substitute>
<!-- #endregion -->

<!-- #region open log modal -->
<horizon-genesys-call-log
  [callLogView]="callLogView"
  isEditMode="true"
  *ngIf="showCallLog"
  (closeEvent)="closeCallLog()"
  (swapView)="swapCallLogModal($event)"
></horizon-genesys-call-log>
<!--#endregion-->
