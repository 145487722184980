import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatCellElement',
})
export class FormatCellElementPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private datePipe: DatePipe
  ) {}

  transform(value: any, colName: string, columns: any[]): any {
    const colType = columns.find((_) => _.name === colName)?.type;

    if (colType === 'date') {
      return this.datePipe.transform(new Date(value), 'dd/MM/YYYY');
    } else {
      return value?.[this.translate.currentLang]
        ? value[this.translate.currentLang]
        : value;
    }
  }
}
