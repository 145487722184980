import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type AccountType = 'company' | 'accountPerson';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  public scopeId$ = new BehaviorSubject<number>(0);

  public accountData$ = new BehaviorSubject<any>({});
  public accountType$ = new BehaviorSubject<AccountType>(null);
  public accountTemplate$ = new BehaviorSubject<any>({});
  public mergeAccountData$ = new BehaviorSubject<any>({});

  public nextInstalment$ = new BehaviorSubject<number>(0);
  public groupExposureId$ = new BehaviorSubject<number>(0);

  public systemDate$ = new BehaviorSubject<any>({});

  public accountTreeNodes$ = new BehaviorSubject<any>([]);

  public legalTitleHolder$ = new BehaviorSubject<string>('');

  public accountCustomersTitle$ = new BehaviorSubject<any>([]);

  constructor() {}

  public setScopeId(value: number): void {
    this.scopeId$.next(value);
  }

  public getScopeId(): number {
    return this.scopeId$.getValue();
  }

  public setAccountType(value: AccountType): void {
    this.accountType$.next(value);
  }

  public getAccountType(): any {
    return this.accountType$.getValue();
  }

  public getAccountTypeChanges(): Observable<any> {
    return this.accountType$.asObservable();
  }

  public setAccountData(value: any): void {
    this.accountData$.next(value);
  }

  public getAccountData(): any {
    return this.accountData$.getValue();
  }

  public getAccountDataChanges(): Observable<any> {
    return this.accountData$.asObservable();
  }

  public setAccountTemplate(value: any): void {
    this.accountTemplate$.next(value);
  }

  public getAccountTemplate(): any {
    return this.accountTemplate$.getValue();
  }

  public getAccountTemplateChanges(): Observable<any> {
    return this.accountTemplate$.asObservable();
  }

  public setMergeAccountData(value: any): void {
    this.mergeAccountData$.next(value);
  }

  public getMergeAccountData(): any {
    return this.mergeAccountData$.getValue();
  }

  public getMergeAccountDataChanges(): Observable<any> {
    return this.mergeAccountData$.asObservable();
  }

  public setNextInstalment(value: number): void {
    this.nextInstalment$.next(value);
  }

  public getNextInstalment(): number {
    return this.nextInstalment$.getValue();
  }

  public setGroupExposureId(value: number): void {
    this.groupExposureId$.next(value);
  }

  public getGroupExposureId(): number {
    return this.groupExposureId$.getValue();
  }

  public getSystemDate(): number {
    return this.systemDate$.getValue();
  }

  public setSystemDate(value: Date): void {
    this.systemDate$.next(value);
  }

  public getSystemDateChanges(): Observable<any> {
    return this.systemDate$.asObservable();
  }

  public setAccountTreeNodes(value: any): void {
    this.accountTreeNodes$.next(value);
  }

  public getAccountTreeNodes(): any {
    return this.accountTreeNodes$.getValue();
  }

  public getAccountTreeNodesChanges(): Observable<any> {
    return this.accountTreeNodes$.asObservable();
  }

  public setLegalTitleHolder(value: string): void {
    this.legalTitleHolder$.next(value);
  }

  public getLegalTitleHolder(): Observable<string> {
    return this.legalTitleHolder$.asObservable();
  }

  public setAccountCustomersTitle(value: any): void {
    this.accountCustomersTitle$.next(value);
  }

  public getAccountCustomersTitle(): Observable<any> {
    return this.accountCustomersTitle$.asObservable();
  }
}
